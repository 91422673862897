<template>
  <div  class='process'>
    <div class="box-card" style="">
      <iframe v-if="url" :src="url" width="100%" height="600px" frameborder="0"></iframe>
      <el-empty v-else description="description" />
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, getCurrentInstance, onMounted } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { jupyterhubTokens,addJupyterhubTokens } from "@/api/test.js";


export default defineComponent({
  name: "jupyterhub",
  setup() {

    onMounted(() => {
      jupyterhubTokenss()
    })
    let data = reactive({
      url: "",
    })
    // 查找 data 数据
    let jupyterhubTokenss = () => {
      
      jupyterhubTokens().then(res => {
        if (res.data.code === '200' && res.data.data && res.data.data.token) {
          data.url = "http://172.16.213.200:8000/user/" + res.data.data.user + "/lab?token=" + res.data.data.token
          console.log(" data.url", data.url);
        } else {
          // let username = JSON.parse(sessionStorage.getItem("user")).userId
          // addJupyterhubTokens({user: username}).then(res => { })
          data.url = ""
          ElMessage({ type: 'error', message: '请联系管理员！' })
        }
      }).catch(() => {
        if (!navigator.onLine) {
          ElMessage({ type: 'error', message: '网络已断开' })
          location.reload()
        }
      })
    }

    return {
      ...toRefs(data),
    }
  }
})
</script>

<style scoped lang="less">
.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 10px;
  border-left: 6px solid #3B6EFB;
}
.content {
  line-height: 40px;
  margin: 10px 0 0 30px;
}
// :deep(.el-card__body){
//   height: calc(100vh - 165px);
// }
.process{
  height: calc(100vh - 140px);
  overflow: auto;
  border-radius: 6px;
  background-color: #fff;
  padding:0px 20px;
  padding-top:20px;

}
</style>
